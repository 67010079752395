import React, { useState } from "react";
import "./mobile.home.css";
import "./mobile.home.media.css";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { QRFormPage } from "../Components/QRFormPage";

interface MobileHomePageProps {}

export const MobileHomePage: React.FC<MobileHomePageProps> = () => {
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showHome, setShowHome] = useState<boolean>(true);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [subTitleData, setSubTitleData] = useState<String>(
    `Your Safety Code | ${window.innerWidth} x ${window.innerHeight}`
  );

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const showInfoPanel = () => {
    setShowInfo(true);
    setShowHome(false);
    setShowForm(false);
  };

  const showFormPanel = () => {
    setShowForm(!showForm);
    setShowHome(!showHome);
  };

  const showHomePanel = () => {
    setShowForm(false);
    setShowInfo(false);
    setShowHome(true);
  };

  return (
    <>
      <Card sx={{ width: 350 }}>
        {showHome && (
          <CustomTitle
            title="Rescue QR"
            subheader={subTitleData}
            action={
              <IconButton aria-label="settings" onClick={showInfoPanel}>
                <HelpOutlineOutlinedIcon />
              </IconButton>
            }
          />
        )}

        {showInfo && (
          <CustomTitleWithAvatar
            title="Rescue QR"
            avatar={
              <Avatar
                alt="RescueQR"
                src={window.location.origin + "/images/logo/logo.png"}
              />
            }
            subheader={subTitleData}
            action={
              <IconButton aria-label="settings" onClick={showHomePanel}>
                <HomeOutlinedIcon />
              </IconButton>
            }
          />
        )}

        {showForm && (
          <CustomTitleWithAvatar
            title="Rescue QR"
            avatar={
              <Avatar
                alt="RescueQR"
                src={window.location.origin + "/images/logo/logo.png"}
              />
            }
            subheader={subTitleData}
            action={
              <IconButton aria-label="settings" onClick={showHomePanel}>
                <HomeOutlinedIcon />
              </IconButton>
            }
          />
        )}

        {showInfo && (
          <CardMedia
            id="howto"
            component="img"
            image={window.location.origin + "/images/howto.png"}
            style={{ width: "330px", marginLeft: "10px" }}
            alt="Rescue QR"
          />
        )}
        {showHome && (
          <CardMedia
            id="home"
            component="img"
            image={window.location.origin + "/images/logo/logo.png"}
            className="home-logo"
            alt="Rescue QR"
          />
        )}

        <CardContent>
          {/* <Typography variant="body2" color="text.secondary"></Typography> */}
          {showForm && (
            <CustomQRPage>
              <QRFormPage></QRFormPage>
            </CustomQRPage>
          )}
        </CardContent>
        <CardActions disableSpacing>
          {showHome && (
            <CustomGenerateButton variant="outlined" onClick={showFormPanel}>
              Generate Rescue QR
            </CustomGenerateButton>
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default MobileHomePage;

export const CustomQRPage = styled.div`
  //   margin-left: 30px;
`;

export const CustomTitle = styled(CardHeader)`
  margin-left: 30%;
  font-size: 10px;
  .MuiCardHeader-subheader {
    margin-left: 24px;
    font-size: 10px;
  }
`;

export const CustomTitleWithAvatar = styled(CardHeader)`
  font-size: 10px;
  .MuiCardHeader-subheader {
    font-size: 10px;
  }
`;

export const CustomGenerateButton = styled(Button)`
  margin-left: 30%;
`;
