import { useEffect, useState } from "react";
import { MobileHomePage } from "./Pages/MobileHomePage";
import { HomePage } from "./Pages/HomePage";

export const AppPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    // checkGeoFence();
    const userAgent = window.navigator.userAgent;
    const mobileKeywords = [
      "Mobile",
      "iPhone",
      "Android",
      "Windows Phone",
      "BlackBerry"
    ];
    const checkIsMobile = (): void => {
      const isMobileDevice = mobileKeywords.some((keyword) =>
        userAgent.includes(keyword)
      );
      setIsMobile(isMobileDevice);
    };
    checkIsMobile();
  }, []);
  // return <>{isMobile ? <MobileHomePage></MobileHomePage> : <HomePage />}</>;
  return (
    <>
      <MobileHomePage></MobileHomePage>
    </>
  );
};

export default AppPage;
